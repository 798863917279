class PeerService {

    constructor() {
  //  if (!this.peer) {                                          // Normal Code
  //     this.peer = new RTCPeerConnection({
  //       // iceServers: [
  //       //   {
  //       //     "urls": "stun:stun.l.google.com:19302"
  //       //   }
  //       // ],
  //     });
  //   }

    if (!this.peer) {                                              // TURN Server
      this.peer = new RTCPeerConnection({
        iceServers: [
          {
            urls: "stun:44.225.196.243:3478",
          },
        ],
      });
    }
    
  }


  // constructor() {
  //   if (!this.peer) {
  //     this.peer = new RTCPeerConnection({
  //       iceServers: [
  //         { urls: ["stun:bn-turn1.xirsys.com"] },
  //         {
  //           username:
  //             "_TcPDRba7nIyJgswRbj-pBMDzvTMTKMGoArZYxfUCQF7FbMNxwZmAZd0G7kMb06rAAAAAGUyVIpyb211eWU=",
  //           credential: "5c437216-6f32-11ee-9cb1-0242ac140004",
  //           urls: [
  //             "turn:bn-turn1.xirsys.com:80?transport=udp",
  //             "turn:bn-turn1.xirsys.com:3478?transport=udp",
  //             "turn:bn-turn1.xirsys.com:80?transport=tcp",
  //             "turn:bn-turn1.xirsys.com:3478?transport=tcp",
  //             "turns:bn-turn1.xirsys.com:443?transport=tcp",
  //             "turns:bn-turn1.xirsys.com:5349?transport=tcp",
  //           ],
  //         },
  //       ],
  //     });
  //   }
  // }

  // constructor() {
  //   if (!this.peer) {
  //     this.peer = new RTCPeerConnection({
  //       iceServers: [
  //         { urls: ["stun:turn.advaintai.com:3478"] },
  //         {
  //           username: "admin",
  //           credential: "advaint123",
  //           urls: [
  //             "turn:turn.advaintai.com:80?transport=udp",
  //             "turn:turn.advaintai.com:3478?transport=udp",
  //             "turn:turn.advaintai.com:80?transport=tcp",
  //             "turn:turn.advaintai.com:3478?transport=tcp",
  //             // "turns:turn.advaintai.com:443?transport=tcp",
  //             // "turns:turn.advaintai.com:5349?transport=tcp",
  //           ],
  //         },
  //       ],
  //     });
  //   }
  // }
  // constructor() {
  //   if (!this.peer) {
  //     this.peer = new RTCPeerConnection({
  //       iceServers: [],
  //     });
  //   }
  // }

  async getAnswer(offer) {
    if (this.peer) {
      await this.peer.setRemoteDescription(offer);
      const ans = await this.peer.createAnswer();
      await this.peer.setLocalDescription(new RTCSessionDescription(ans));
      return ans;
    }
  }

  async setLocalDescription(ans) {
    if (this.peer) {
      await this.peer.setRemoteDescription(new RTCSessionDescription(ans));
    }
  }

  async getOffer() {
    if (this.peer) {
      const offer = await this.peer.createOffer();
      await this.peer.setLocalDescription(new RTCSessionDescription(offer));
      return offer;
    }
  }
}

export default new PeerService();
